import { configureStore } from "@reduxjs/toolkit";
import bannerReducer from "../features/Banner/bannerSlice";
import specialitiesReducer from "../features/Specialities/specialitiesSlice";
import doctorReducer from "../features/Doctor/doctorSlice";
import blogReducer from "../features/Blogs/blogSlice";
import videoReducer from "../features/Video/videoSlice";
import appointmentReducer from "../features/BookAppointment/AppointmentAlice";
import directorReducer from "../features/Director/directorSlice";
import mediaReducer from "../features/MediaCenter/mediaSlice";
import mediaVideoReducer from "../features/MediaVideo/mediaVideoSlice";
import carrerReducer from "../features/Carrer/carrerSlice";

export const store = configureStore({
  reducer: {
    banner: bannerReducer,
    specialities: specialitiesReducer,
    doctor: doctorReducer,
    blogs: blogReducer,
    video: videoReducer,
    appointment: appointmentReducer,
    director: directorReducer,
    carrer: carrerReducer,
    media: mediaReducer,
    mediaVideo: mediaVideoReducer,
  },
});
