import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config/config";

export const getSpecialities = createAsyncThunk(
  "Specialities/getActive",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${config.base.url}/v1/user/specialities/getSpecialties`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Failed to fetch active Specialities"
      );
    }
  }
);

export const getDoctorsBySpecialty = createAsyncThunk(
  "appointments/getDoctorsBySpecialty",
  async (specialtyId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${config.base.url}/v1/user/bookappointment/specialty/${specialtyId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch doctors");
    }
  }
);

export const getAvailableDays = createAsyncThunk(
  "appointments/getAvailableDays",
  async (doctorId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${config.base.url}/v1/user/bookappointment/availability/days/${doctorId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch available days");
    }
  }
);

export const getAvailableDates = createAsyncThunk(
  "appointments/getAvailableDates",
  async (day, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${config.base.url}/v1/user/bookappointment/availability/dates/${day}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch available dates");
    }
  }
);

export const getAvailableTimes = createAsyncThunk(
  "appointments/getAvailableTimes",
  async ({ doctorId, date }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${config.base.url}/v1/user/bookappointment/availability/times/${doctorId}/${date}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch available times");
    }
  }
);

export const createAppointment = createAsyncThunk(
  "appointments/createAppointment",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.base.url}/v1/user/bookappointment/createAppointment`,
        formData,
        {
          withCredentials: true,
        }
      );

      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to create appointment";
      return rejectWithValue(errorMessage);
    }
  }
);

export const getAppointment = createAsyncThunk(
  "Appointment/getActive",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${config.base.url}/v1/user/bookappointment/getAppointment`,
        { withCredentials: true }
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Failed to fetch active Appointment"
      );
    }
  }
);
