import { createSlice } from "@reduxjs/toolkit";
import { getDirector } from "./directorApi";

// Slice for managing master doctor state
const directorSlice = createSlice({
  name: "director",
  initialState: {
    director: [],
    loading: false,
    error: null,
    dataLimit: 0,
    currentPage: 0,
    totalPages: 0,
    totalDirector: 0,
  },
  extraReducers: (builder) => {
    // GET Banner FILTER WISE DATA
    builder.addCase(getDirector.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getDirector.fulfilled, (state, action) => {
      state.loading = false;
      state.director = action.payload;
      state.dataLimit = action.payload.meta.limit;
      state.currentPage = action.payload.meta.page;
      state.totalPages = action.payload.meta.totalPages;
      state.totalDirector = action.payload.meta.total;
    });
    builder.addCase(getDirector.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default directorSlice.reducer;
