import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config/config";

// GET Director
export const getDirector = createAsyncThunk(
  "Director/getActive",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${config.base.url}/v1/user/director/getDirector`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Failed to fetch active Director"
      );
    }
  }
);
