import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config/config";

export const getMediaVideo = createAsyncThunk(
  "MediaVideo/getActive",
  async (
    {
      id,
      status = "All",
      search = "",
      page = 1,
      limit = 10,
      startDate,
      endDate,
      paginate,
    },
    thunkAPI
  ) => {
    try {
      const { data } = await axios.get(
        `${config.base.url}/v1/user/mediaVideo/get-mediaVideo`,
        {
          params: {
            id,
            status,
            search,
            page,
            limit,
            startDate,
            endDate,
            paginate,
          },
        }
      );

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Failed to fetch active MediaVideo"
      );
    }
  }
);
