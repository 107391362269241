import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config/config";

export const getMedia = createAsyncThunk(
  "Media/getActive",
  async (
    {
      id,
      status = "All",
      search = "",
      page = 1,
      limit = 10,
      startDate,
      endDate,
      paginate,
    },
    thunkAPI
  ) => {
    try {
      const { data } = await axios.get(
        `${config.base.url}/v1/user/media/get-media`,
        {
          params: {
            id,
            status,
            search,
            page,
            limit,
            startDate,
            endDate,
            paginate,
          },
        }
      );
      console.log(data, "data");
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Failed to fetch active Media"
      );
    }
  }
);

export const getSingleMedia = createAsyncThunk(
  "Media/getSingle",
  async (slug, thunkAPI) => {
    console.log(slug, "===slug");
    try {
      const { data } = await axios.get(
        `${config.base.url}/v1/user/media/getSingle-media/${slug}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Failed to fetch Media details"
      );
    }
  }
);
