import {
  ic_about,
  ic_address,
  ic_carrer,
  ic_email,
  ic_home,
  ic_media,
  ic_phone,
  ic_research,
  ic_treatment,
} from "../assets/layout";
import siteMetadata from "../utils/siteMetaData";

const navItems = [
  { src: ic_home, alt: "home", text: "Home", pathname: "/" },
  { src: ic_about, alt: "about us", text: "About us", pathname: "/about" },
  {
    src: ic_treatment,
    alt: "Treatment",
    text: "Treatment",
    pathname: "/treatment",
    icon: "⮟",
  },
  {
    src: ic_research,
    alt: "Academics & Research",
    text: "Academics & Research",
    pathname: "/academics",
  },
  {
    src: ic_media,
    alt: "Media Center",
    text: "Media Center",
    pathname: "/media-center",
    icon: "⮟",
  },
  { src: ic_carrer, alt: "Carrer", text: "Carrer", pathname: "/carrer" },
];

const additionalItems = [
  { text: "Find Doctors", pathname: "/find-doctors" },
  { text: "My Appointment", pathname: "/my-appointment" },
  { text: "Awards", pathname: "/awards" },
  { text: "Blogs", pathname: "/blogs" },
  { text: "FAQ’s", pathname: "/faq" },
  { text: "Contact us", pathname: "/contact-us" },
];

const socialLinks = [
  {
    // href: siteMetadata.location,
    icon: ic_address,
    label: "Reach out to me via location",
    title: "Location",
    description: siteMetadata.location,
  },
  {
    // href: `tel:${siteMetadata.phoneNumber}`,
    icon: ic_phone,
    label: "Reach out to me via Phone Number 24*7",
    title: "Phone Number",
    description: siteMetadata.phoneNumber,
  },
  {
    // href: `mailto:${siteMetadata.email}`,
    icon: ic_email,
    label: "Reach out to me via Gmail",
    title: "Email",
    description: siteMetadata.email,
  },
];

export { navItems, additionalItems, socialLinks };
