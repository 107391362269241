import { createSlice } from "@reduxjs/toolkit";
import { getMedia, getSingleMedia } from "./mediaApi";

// Slice for  Media state
const mediaSlice = createSlice({
  name: "media",
  initialState: {
    media: [],
    singleMedia: null,
    loading: false,
    error: null,
    dataLimit: 0,
    currentPage: 0,
    totalPages: 0,
    totalMedia: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(getMedia.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getMedia.fulfilled, (state, action) => {
      state.loading = false;
      state.media = action.payload;
      state.dataLimit = action.payload.meta.limit;
      state.currentPage = action.payload.meta.page;
      state.totalPages = action.payload.meta.totalPages;
      state.totalMedia = action.payload.meta.total;
    });
    builder.addCase(getMedia.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getSingleMedia.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSingleMedia.fulfilled, (state, action) => {
      state.loading = false;
      state.singleMedia = action.payload;
    });
    builder.addCase(getSingleMedia.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default mediaSlice.reducer;
