import { createSlice } from "@reduxjs/toolkit";
import { getCarrer } from "./carrerApi";

// Slice for  Media state
const mediaSlice = createSlice({
  name: "carrer",
  initialState: {
    carrer: [],
    loading: false,
    error: null,
    dataLimit: 0,
    currentPage: 0,
    totalPages: 0,
    totalCarrer: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(getCarrer.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCarrer.fulfilled, (state, action) => {
      state.loading = false;
      state.carrer = action.payload;
      state.dataLimit = action.payload.meta.limit;
      state.currentPage = action.payload.meta.page;
      state.totalPages = action.payload.meta.totalPages;
      state.totalCarrer = action.payload.meta.total;
    });
    builder.addCase(getCarrer.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default mediaSlice.reducer;
