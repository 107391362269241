import { createSlice } from "@reduxjs/toolkit";
import {
  getAppointment,
  getAvailableDates,
  getAvailableDays,
  getAvailableTimes,
  getDoctorsBySpecialty,
  getSpecialities,
} from "./Appointment";

// Slice
const appointmentsSlice = createSlice({
  name: "appointments",
  initialState: {
    appointment: [],
    specialities: [],
    doctors: [],
    availableDays: [],
    availableDates: [],
    availableTimes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Specialities
    builder.addCase(getSpecialities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSpecialities.fulfilled, (state, action) => {
      state.loading = false;
      state.specialities = action.payload;
    });
    builder.addCase(getSpecialities.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Doctors by specialty
    builder.addCase(getDoctorsBySpecialty.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDoctorsBySpecialty.fulfilled, (state, action) => {
      state.loading = false;
      state.doctors = action.payload;
    });
    builder.addCase(getDoctorsBySpecialty.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Available days
    builder.addCase(getAvailableDays.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAvailableDays.fulfilled, (state, action) => {
      state.loading = false;
      state.availableDays = action.payload;
    });
    builder.addCase(getAvailableDays.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Available dates
    builder.addCase(getAvailableDates.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAvailableDates.fulfilled, (state, action) => {
      state.loading = false;
      state.availableDates = action.payload;
    });
    builder.addCase(getAvailableDates.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Available times
    builder.addCase(getAvailableTimes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAvailableTimes.fulfilled, (state, action) => {
      state.loading = false;
      state.availableTimes = action.payload;
    });
    builder.addCase(getAvailableTimes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Available times
    builder.addCase(getAppointment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAppointment.fulfilled, (state, action) => {
      state.loading = false;
      state.appointment = action.payload.data;
    });
    builder.addCase(getAppointment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

// Export actions and reducer
export default appointmentsSlice.reducer;
