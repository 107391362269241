import { Link } from "react-router-dom";

const NavItem = ({ src, alt, text, icon, pathname, asLink = true }) => {
  const content = (
    <div className="flex items-center gap-2">
      {src && <img src={src} alt={alt} />}
      <span className="2xl:text-base text-xs text-primary font-semibold">
        {text}
        {icon && (
          <span className="text-secondary font-bold text-base">{icon}</span>
        )}
      </span>
    </div>
  );

  if (asLink) {
    return <Link to={pathname}>{content}</Link>;
  }

  return content;
};

export default NavItem;
