import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NavBar from "../layout/Navbar";
import Footer from "../layout/Footer";
import SocialMedia from "../layout/SocialMedia";
import ScrollToTopButton from "../utils/ScrollTopButton";
import ScrollToTop from "./ScrollTop";
import Cookies from "js-cookie";

const AuthenticatedRoute = ({ children }) => {
  const token = Cookies.get("token");

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const LoginPage = lazy(() => import("../pages/UserPage/LoginPage"));
const SignupPage = lazy(() => import("../pages/UserPage/SignupPage"));
const ForgotPassword = lazy(() => import("../pages/UserPage/ForgotPassword"));
const VerifyOtpPage = lazy(() => import("../pages/UserPage/VerifyOtpPage"));
const CreateNewPass = lazy(() => import("../pages/UserPage/CreateNewPass"));
const Home = lazy(() => import("../pages/Home/Home"));
const AboutUs = lazy(() => import("../pages/AboutUs/AboutUs"));
const FindDoctors = lazy(() => import("../pages/FindDoctor/DoctorPage"));
const Blogs = lazy(() => import("../pages/Blogs/BlogPage"));
const SingleBlog = lazy(() => import("../pages/Blogs/SingleBlogs"));
const ContactUs = lazy(() => import("../pages/ContactUs/ContactUs"));
const PatientProcess = lazy(() =>
  import("../pages/Patient_Process/PatientProcess")
);
const VisitorGuideline = lazy(() =>
  import("../pages/VisitorGuidlines/VisitorGuideline")
);
const ViewDoctorProfile = lazy(() =>
  import("../pages/FindDoctor/ViewDoctorProfile")
);
const BookAppointment = lazy(() =>
  import("../pages/BookAppointment/BookAppointment")
);
const Faq = lazy(() => import("../pages/Faq/Faq"));
const Awards = lazy(() => import("../pages/Awards/Awards"));
const Academics = lazy(() => import("../pages/Academics/Academics"));
const SingleSpeciality = lazy(() =>
  import("../pages/Treatment/SingleSpeciality")
);
const Carrer = lazy(() => import("../pages/Carrer/Carrer"));
const MediaCenter = lazy(() => import("../pages/MediaCenter/MediaCenter"));
const PressRelease = lazy(() => import("../pages/MediaCenter/SingleNewlist"));
const BookDoctorPage = lazy(() => import("../pages/FindDoctor/BookDoctorPage"));
const Myappointment = lazy(() =>
  import("../pages/MyAppointment/Myappointment")
);
const Treatment = lazy(() => import("../pages/Treatment/Treatment"));

const RoutePaths = [
  {
    path: "/login",
    component: LoginPage,
    meta: { authRoute: false },
  },
  {
    path: "/signup",
    component: SignupPage,
    meta: { authRoute: false },
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    meta: { authRoute: false },
  },
  {
    path: "/verify-otp",
    component: VerifyOtpPage,
    meta: { authRoute: false },
  },
  {
    path: "/create-password",
    component: CreateNewPass,
    meta: { authRoute: false },
  },
  { path: "/", component: Home, meta: { authRoute: false } },
  { path: "/about", component: AboutUs, meta: { authRoute: false } },
  { path: "/find-doctors", component: FindDoctors, meta: { authRoute: false } },
  { path: "/blogs", component: Blogs, meta: { authRoute: false } },
  { path: "/blogs/:slug", component: SingleBlog, meta: { authRoute: false } },
  { path: "/contact-us", component: ContactUs, meta: { authRoute: false } },
  {
    path: "/patient-process",
    component: PatientProcess,
    meta: { authRoute: false },
  },
  {
    path: "/visitors",
    component: VisitorGuideline,
    meta: { authRoute: false },
  },
  {
    path: "/doctor/:slug",
    component: ViewDoctorProfile,
    meta: { authRoute: false },
  },
  {
    path: "/book-appointment",
    component: BookAppointment,
    meta: { authRoute: false },
  },
  {
    path: "/faq",
    component: Faq,
    meta: { authRoute: false },
  },
  {
    path: "/awards",
    component: Awards,
    meta: { authRoute: false },
  },
  {
    path: "/academics",
    component: Academics,
    meta: { authRoute: false },
  },
  {
    path: "/specialty/:slug",
    component: SingleSpeciality,
    meta: { authRoute: false },
  },
  {
    path: "/treatment",
    component: Treatment,
    meta: { authRoute: false },
  },
  {
    path: "/view-treatment/:specialityName",
    component: SingleSpeciality,
    meta: { authRoute: false },
  },
  {
    path: "/carrer",
    component: Carrer,
    meta: { authRoute: false },
  },
  {
    path: "/media-center",
    component: MediaCenter,
    meta: { authRoute: false },
  },
  {
    path: "/press-release/:slug",
    component: PressRelease,
    meta: { authRoute: false },
  },
  {
    path: "/book-appointment/:slug",
    component: BookDoctorPage,
    meta: { authRoute: false },
  },
  {
    path: "/my-appointment",
    component: Myappointment,
    meta: { authRoute: false },
  },
];

function Router() {
  return (
    <>
      <SocialMedia />
      <NavBar />
      <ScrollToTop />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {RoutePaths.map((route, index) => {
            const Component = route.component;
            if (route.path === "/my-appointment") {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <AuthenticatedRoute>
                      <Component />
                    </AuthenticatedRoute>
                  }
                />
              );
            }
            return (
              <Route key={index} path={route.path} element={<Component />} />
            );
          })}
        </Routes>
      </Suspense>
      <ScrollToTopButton />
      <Footer />
    </>
  );
}

export default Router;
