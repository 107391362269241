import { bg_footer, ic_fb, ic_instagram, ic_youtube } from "../assets/layout";
import {
  EM_Charitable_trust,
  pp_savani_group,
  pp_savani,
  yojana_Logo,
} from "../assets";
import React from "react";
import { socialLinks } from "../lib/index";
import { SocialLink } from "../utils";
import { Link } from "react-router-dom";
import siteMetadata from "../utils/siteMetaData";

const FooterSection = ({ title, items }) => (
  <>
    <h1 className="border-b pb-3 text-xl border-[#EF0E5B]">{title}</h1>
    <ul className="grid grid-cols-2 xl:gap-4 gap-2 mt-5 font-medium xl:text-base lg:text-sm text-xs ">
      {items.map((item, index) => (
        <li key={index} className="truncate" title={item}>
          ● {item}
        </li>
      ))}
    </ul>
  </>
);

const quickLinks = [
  "Home",
  "Download Brochure",
  "Our Story",
  "Transplant Compliance",
  "Find a Doctor",
  "Health Packages",
  "Blog",
  "Privacy Policy",
  "Visitors Guidelines",
  "Career",
];

const specialities = [
  "Cardiac Sciences",
  "Neurology",
  "Pediatric Neurology",
  "Brain & Spine Surgery",
  "Neuro Interventional Procedures",
  "Nephrology and Transplant Medicine",
  "Urology and Renal Surgery",
  "Kidney Transplant",
  "Kidney Dialysis",
  "Advanced Laparoscopic & Minimally Invasive Surgery",
  "Advanced Centre for Varicose Veins",
  "Ophthalmology",
  "Dental",
  "Oncology",
  "Plastic Surgery",
  "Orthopaedics",
  "Sports Injuries",
  "Joint Replacement",
  "Gastroenterology & Hepatology",
  "Psychiatrist",
  "Clinical Psychology",
  "Internal Medicine",
  "Bone Marrow Transplant",
  "Stem Cell Centre",
  "Pulmonology & Sleep Medicine",
  "Rheumatology",
  "Obstetrics & Gynecology",
  "Infertility & IUI",
  "ENT",
  "Emergency & Trauma",
];

const Footer = () => {
  return (
    <>
      <footer className="flex flex-col items-center justify-between">
        <section
          className="p-5 bg-no-repeat min-h-creen min-w-full bg-cover"
          style={{
            backgroundImage: `url(${bg_footer})`,
            backgroundSize: "cover",
          }}
        >
          <div className="xl:container mx-auto text-white font-poppins xl:mt-11 mt-5">
            <div className="grid md:grid-cols-9 sm:grid-cols-2 grid-cols-1 gap-4 border-b border-b-[#D5D5D5] pb-6">
              <Link
                to={"/"}
                className="md:col-span-4 bg-white w-full h-fit px-5 py-4 rounded-3xl flex justify-evenly items-center"
              >
                {[EM_Charitable_trust, pp_savani_group, pp_savani].map(
                  (logo, index) => (
                    <React.Fragment key={index}>
                      <img
                        src={logo}
                        alt="pp_savani_group"
                        className="2xl:w-32 2xl:h-32 h-20 w-20"
                      />
                      {index < 2 && (
                        <div className="h-16 w-[0.4px] bg-primary" />
                      )}
                    </React.Fragment>
                  )
                )}
              </Link>

              <address className="md:col-span-4 md:col-start-1 md:row-start-2 font-medium xl:mt-5 space-y-3 not-italic">
                {socialLinks.map(
                  ({ href, icon, label, description, title }, index) => (
                    <SocialLink
                      key={index}
                      href={href}
                      icon={icon}
                      label={label}
                      description={description}
                      title={title}
                    />
                  )
                )}
                <img src={yojana_Logo} alt="yojana_Logo" className="mx-auto" />
              </address>

              <nav className="md:col-span-4 md:col-start-1 md:row-start-3">
                <FooterSection title="Quick Links :" items={quickLinks} />
              </nav>
              <section className="md:col-span-5 md:row-span-3 md:col-start-5 md:row-start-1 xl:ml-10">
                <FooterSection
                  title="Top Specialities :"
                  items={specialities}
                />
              </section>
            </div>

            <section className="grid md:grid-cols-2 grid-cols-1 xl:mt-9 mt-4 items-center text-xs">
              <article>
                <h1>Advisory Notice:</h1>
                <p>
                  1. Max Hospitals never solicit information like bank details
                  or payment authorization via links. Please be cautious about
                  sharing sensitive information.
                </p>
                <p className="mt-3">
                  2. P.P. Savani Hospital does not charge for job opportunities.
                  Verify offers with official sources.
                </p>
              </article>

              <nav className="flex flex-wrap gap-2 justify-center text-xs mt-3">
                {[
                  "Privacy Policy",
                  "Disclaimer",
                  "Accessibility",
                  "Terms of Use",
                  "Sitemap",
                ].map((link, index) => (
                  <React.Fragment key={index}>
                    <Link href="/#">{link}</Link>
                    {index < 4 && "|"}
                  </React.Fragment>
                ))}
              </nav>
            </section>
          </div>
        </section>
        <div className="bg-secondary w-full md:px-5">
          <div className="xl:container mx-auto text-white py-2 font-goldplay font-medium flex sm:flex-row flex-col gap-2 items-center justify-between">
            <p className="xl:text-base text-xs">
              <span className="font-normal">Develop & Design By </span>
              MajesticAI & Pixel’s Designvilla
            </p>
            <div className="flex items-center xl:gap-8 gap-3">
              <p className="xl:text-base text-xs">
                ©P.P. SAVANI GROUP 2024.{" "}
                <span className="font-normal">All Right Reserved.</span> 
              </p>
              <div className="items-center gap-2 md:flex hidden">
                <SocialLink
                  href={siteMetadata.fb}
                  icon={ic_fb}
                  label="Reach out to me via Facebook"
                  title="Facebook"
                />
                <SocialLink
                  href={siteMetadata.instagram}
                  icon={ic_instagram}
                  label="Reach out to me via instagram"
                  title="instagram"
                />
                {/* <SocialLink
                  icon={ic_twitter}
                  label="Reach out to me via Twitter"
                  title="Twitter"
                /> */}
                <SocialLink
                  href={siteMetadata.youtube}
                  icon={ic_youtube}
                  label="Reach out to me via YouTube"
                  title="YouTube"
                />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
