import React from "react";
import { AiOutlineArrowUp } from "react-icons/ai";

const ScrollToTopButton = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", 
    });
  };

  return (
    <button
      onClick={handleScrollToTop}
      className="fixed bottom-4 right-4 bg-primary text-white border-white border-2 p-3 rounded-lg shadow-lg transition duration-300"
      aria-label="Scroll to top"
    >
      <AiOutlineArrowUp size={24} />
    </button>
  );
};

export default ScrollToTopButton;
