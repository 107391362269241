// config.js
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

const API_URL_LOCAL = process.env.REACT_APP_BASE_URL_LOCAL;
const API_URL_PROD = process.env.REACT_APP_BASE_URL_PROD;

const config = {
  base: {
    url: ENVIRONMENT === "prod" ? API_URL_PROD : API_URL_LOCAL,
  },
  domain: ENVIRONMENT === "prod" ? "ppsavanihospital.org" : ".localhost",
  ENVIRONMENT
};

export { config, ENVIRONMENT };
